

// import React, { useState, useMemo, useEffect, useCallback } from 'react';
// import { MapContainer, TileLayer, Marker, Popup, Circle, useMap } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import './MapView.css';
// import MarkerClusterGroup from 'react-leaflet-cluster';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
// import jobData from '../data/jobs.json';

// import houseIcon from '../data/street-view-solid1.svg';
// import locationPinIcon from '../data/markerCircle.svg';

// // Import SVG icons for map controls
// import minusSolid from '../data/minus-solid.svg';
// import plusSolid from '../data/plus-solid.svg';
// import crosshairsSolid from '../data/crosshairs-solid.svg';

// // Geocode helper function: converts an address string into coordinates using Mapbox Geocoding API.
// async function geocodeAddress(address) {
//   const encodedAddress = encodeURIComponent(address);
//   // Replace 'YOUR_MAPBOX_TOKEN' with your actual Mapbox access token.
//   const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=pk.eyJ1Ijoiam9zaHloYWdlIiwiYSI6ImNtMmkxN3FhdzBmeDcyaXE4cXVuOGF2MHYifQ.wjchcDg7q_zT_kJ7qxJEiw`;
//   const response = await fetch(url);
//   if (!response.ok) {
//     throw new Error('Geocoding request failed');
//   }
//   const data = await response.json();
//   if (!data.features || data.features.length === 0) {
//     throw new Error('No matching location found');
//   }
//   const [lng, lat] = data.features[0].center;
//   return { latitude: lat, longitude: lng };
// }

// // Create custom icons
// const customIcon = L.icon({
//   iconUrl: locationPinIcon,
//   iconSize: [30, 30],
//   iconAnchor: [11, 36],
//   popupAnchor: [0, -36],
// });

// const userIcon = L.icon({
//   iconUrl: houseIcon,
//   iconSize: [40, 55],
//   iconAnchor: [15, 30],
//   popupAnchor: [0, -30],
// });

// // Component to recenter the map when the center prop changes
// const RecenterMap = ({ center }) => {
//   const map = useMap();
//   useEffect(() => {
//     if (center) {
//       map.setView(center, map.getZoom());
//     }
//   }, [center, map]);
//   return null;
// };

// // Component to update map bounds with a slight delay (debounce) before re-rendering markers
// const MapBoundsUpdater = ({ setMapBounds }) => {
//   const map = useMap();
//   useEffect(() => {
//     let timeoutId;
//     const updateBounds = () => {
//       if (timeoutId) clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => {
//         setMapBounds(map.getBounds());
//       }, 200);
//     };
//     map.on('moveend', updateBounds);
//     updateBounds();
//     return () => {
//       map.off('moveend', updateBounds);
//       if (timeoutId) clearTimeout(timeoutId);
//     };
//   }, [map, setMapBounds]);
//   return null;
// };

// // New component for map controls (zoom in, zoom out, recenter)
// const MapControls = ({ userLocation }) => {
//   const map = useMap();
//   return (
//     <div
//       style={{
//         position: 'absolute',
//         left: '10px',
//         top: '200px', // Adjust as needed for positioning
//         zIndex: 1000,
//         display: 'flex',
//         flexDirection: 'column',
//         gap: '10px',
//       }}
//     >
//       {/* <button
//         onClick={() => map.zoomIn()}
//         style={{
//           padding: '0.5rem',
//           borderRadius: '4px',
//           backgroundColor: '#fff',
//           border: '1px solid #ccc',
//           cursor: 'pointer',
//         }}
//       >
//         +
//       </button>
//       <button
//         onClick={() => map.zoomOut()}
//         style={{
//           padding: '0.5rem',
//           borderRadius: '4px',
//           backgroundColor: '#fff',
//           border: '1px solid #ccc',
//           cursor: 'pointer',
//         }}
//       >
//         –
//       </button>
//       <button
//         onClick={() => map.setView(userLocation, map.getZoom())}
//         style={{
//           padding: '0.5rem',
//           borderRadius: '4px',
//           backgroundColor: '#fff',
//           border: '1px solid #ccc',
//           cursor: 'pointer',
//         }}
//       >
//         Recenter
//       </button> */}

// <button
//         onClick={() => map.zoomIn()}
//         style={{
//           padding: '0.5rem',
//           borderRadius: '4px',
//           backgroundColor: '#fff',
//           border: '1px solid #ccc',
//           cursor: 'pointer',
//         }}
//       >
//         <img src={plusSolid} alt="Zoom In" style={{ width: '24px', height: '24px' }} />
//       </button>
//       <button
//         onClick={() => map.zoomOut()}
//         style={{
//           padding: '0.5rem',
//           borderRadius: '4px',
//           backgroundColor: '#fff',
//           border: '1px solid #ccc',
//           cursor: 'pointer',
//         }}
//       >
//         <img src={minusSolid} alt="Zoom Out" style={{ width: '24px', height: '24px' }} />
//       </button>
//       <button
//         onClick={() => map.setView(userLocation, map.getZoom())}
//         style={{
//           padding: '0.5rem',
//           borderRadius: '4px',
//           backgroundColor: '#fff',
//           border: '1px solid #ccc',
//           cursor: 'pointer',
//         }}
//       >
//         <img src={crosshairsSolid} alt="Recenter" style={{ width: '24px', height: '24px' }} />
//       </button>
//     </div>
//   );
// };

// const MapView = () => {
//   // UI state
//   const [selectedJob, setSelectedJob] = useState(null);
//   const [isDarkMode, setIsDarkMode] = useState(false);
//   const [showSplash, setShowSplash] = useState(true);

//   // userAddress state for splash screen input
//   const [userAddress, setUserAddress] = useState('');

//   // Search bar state
//   const [searchTerm, setSearchTerm] = useState('');
//   const [debouncedSearch, setDebouncedSearch] = useState(searchTerm);

//   // Map location state
//   const [userLocation, setUserLocation] = useState([44.972109856286544, -93.26562490769442]); // Default location
//   const [hasLocation, setHasLocation] = useState(false);
//   const [mapBounds, setMapBounds] = useState(null);

//   const defaultCoordinates = [44.972109856286544, -93.26562490769442]; // Minneapolis default

//   // Larger icon for hover state
//   const hoverIcon = useMemo(
//     () =>
//       L.icon({
//         iconUrl: locationPinIcon,
//         iconSize: [60, 60],
//         iconAnchor: [13, 40],
//         popupAnchor: [0, -40],
//       }),
//     []
//   );

//   // Toggle dark mode
//   const toggleDarkMode = useCallback(() => {
//     setIsDarkMode((prevMode) => !prevMode);
//   }, []);

//   // Handle marker click
//   const handleMarkerClick = useCallback((job) => {
//     requestAnimationFrame(() => {
//       setSelectedJob((prev) => (prev?.jobAddress === job.jobAddress ? prev : job));
//     });
//   }, []);

//   // Handle search input changes
//   const handleSearchChange = useCallback((e) => {
//     setSearchTerm(e.target.value);
//   }, []);

//   // Debounce search input
//   useEffect(() => {
//     const handler = setTimeout(() => {
//       setDebouncedSearch(searchTerm);
//     }, 300);
//     return () => clearTimeout(handler);
//   }, [searchTerm]);

//   // Handle "Continue" button on the splash screen
//   const handleContinue = useCallback(async () => {
//     try {
//       if (userAddress.trim().length > 0) {
//         const coords = await geocodeAddress(userAddress);
//         setUserLocation([coords.latitude, coords.longitude]);
//         setHasLocation(true);
//       } else {
//         setUserLocation([44.975269671312255, -93.26717766140855]);
//         setHasLocation(true);
//       }
//     } catch (error) {
//       console.error('Error geocoding address:', error);
//       setUserLocation([44.975269671312255, -93.26717766140855]);
//       setHasLocation(true);
//     }
//     setShowSplash(false);
//   }, [userAddress]);

//   // Filter jobs based on debounced search term
//   const filteredJobs = useMemo(() => {
//     return jobData.filter((job) => {
//       const term = debouncedSearch.toLowerCase();
//       return (
//         job.jobAddress.toLowerCase().includes(term) ||
//         job.jobZone.toLowerCase().includes(term)
//       );
//     });
//   }, [debouncedSearch]);

//   // Filter jobs based on current map bounds
//   const visibleJobs = useMemo(() => {
//     if (!mapBounds) return filteredJobs;
//     return filteredJobs.filter((job) => {
//       if (
//         !job.coordinates ||
//         job.coordinates.latitude === undefined ||
//         job.coordinates.longitude === undefined
//       ) {
//         return false;
//       }
//       return mapBounds.contains([job.coordinates.latitude, job.coordinates.longitude]);
//     });
//   }, [filteredJobs, mapBounds]);

//   // Create marker elements (using a unique key if available)
//   const markerElements = useMemo(() => {
//     return visibleJobs.map((job) => (
//       <Marker
//         key={job.jobAddress}
//         position={
//           job.coordinates && job.coordinates.latitude !== undefined
//             ? [job.coordinates.latitude, job.coordinates.longitude]
//             : defaultCoordinates
//         }
//         icon={customIcon}
//         eventHandlers={{
//           click: () => handleMarkerClick(job),
//           mouseover: (e) => e.target.setIcon(hoverIcon),
//           mouseout: (e) => e.target.setIcon(customIcon),
//         }}
//       >
//         <Popup>
//           <strong>{job.jobAddress}</strong>
//         </Popup>
//       </Marker>
//     ));
//   }, [visibleJobs, hoverIcon, handleMarkerClick]);

//   // Mapbox tile URLs
//   const lightStyle =
//     'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9zaHloYWdlIiwiYSI6ImNtMmkxN3FhdzBmeDcyaXE4cXVuOGF2MHYifQ.wjchcDg7q_zT_kJ7qxJEiw';
//   const darkStyle =
//     'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9zaHloYWdlIiwiYSI6ImNtMmkxN3FhdzBmeDcyaXE4cXVuOGF2MHYifQ.wjchcDg7q_zT_kJ7qxJEiw';

//   return (
//     <div className={`map-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
//       {showSplash ? (
//         <div
//           className="splash-screen"
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             minHeight: '100vh',
//             boxSizing: 'border-box',
//             padding: '1rem',
//           }}
//         >
//           <div
//             className="splash-content"
//             style={{
//               backgroundColor: 'white',
//               borderRadius: '10px',
//               maxWidth: '700px',
//               width: '100%',
//               boxSizing: 'border-box',
//               padding: '2rem',
//             }}
//           >
//             <div className="splash-banner">
//               <a href="https://hageconcrete.com" target="_blank" rel="noopener noreferrer">
//                 <img
//                   src="https://imagedelivery.net/1Dx57cADC9zKorcji03Zsw/ee83fed1-48f0-4c20-7935-7941cfa5ff00/public"
//                   alt="Hage Concrete Works"
//                   style={{ maxWidth: '100%', height: 'auto', marginBottom: '1rem' }}
//                 />
//               </a>
//             </div>
//             <h1 style={{ marginTop: 0 }}>Welcome to the Hage Concrete Works Project Map</h1>
//             <p>
//               This map helps you explore projects we've completed near you. Click on map markers to see
//               detailed information about each project.
//             </p>
//             <p>
//               Many projects can easily be seen from the street, sidewalk, or alley. We are proud of our
//               work and hope you will take the time to visit some of these completed projects. As a
//               courtesy to our valued customers, please respect their privacy and property.
//             </p>
//             <div style={{ margin: '1rem 0' }}>
//               <label
//                 htmlFor="userAddress"
//                 style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold', color: '#333' }}
//               >
//                 Enter Your Address to see projects near you:
//               </label>
//               <input
//                 id="userAddress"
//                 type="text"
//                 value={userAddress}
//                 onChange={(e) => setUserAddress(e.target.value)}
//                 placeholder="123 Main St, Minneapolis, MN"
//                 style={{
//                   width: '75%',
//                   padding: '0.75rem',
//                   borderRadius: '10px',
//                   border: '1px solid #ddd',
//                   outline: 'none',
//                   fontSize: '1rem',
//                   transition: 'border 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
//                 }}
//                 onFocus={(e) => {
//                   e.target.style.border = '1px solid #007bff';
//                   e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
//                 }}
//                 onBlur={(e) => {
//                   e.target.style.border = '1px solid #ddd';
//                   e.target.style.boxShadow = 'none';
//                 }}
//               />
//             </div>
//             <button className="continue-button" onClick={handleContinue}>
//               Continue
//             </button>
//           </div>
//         </div>
//       ) : (
//         <>
//           {/* Header Section */}
//           <header
//             className="header"
//             style={{ display: 'flex', alignItems: 'center', padding: '1rem', gap: '1rem' }}
//           >
//             <div className="banner">
//               <img
//                 src={
//                   isDarkMode
//                     ? 'https://imagedelivery.net/1Dx57cADC9zKorcji03Zsw/a56c0fc0-61e1-499e-e781-2c8bf5b5b600/public'
//                     : 'https://imagedelivery.net/1Dx57cADC9zKorcji03Zsw/ee83fed1-48f0-4c20-7935-7941cfa5ff00/public'
//                 }
//                 alt="Hage Concrete Works"
//               />
//             </div>
//             <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexGrow: 1 }}>
//               <div className="search-bar">
//                 <input
//                   type="text"
//                   value={searchTerm}
//                   onChange={handleSearchChange}
//                   placeholder="Search by address or job zone..."
//                 />
//               </div>
//               <button
//                 style={{
//                   padding: '0.75rem 1rem',
//                   borderRadius: '5px',
//                   backgroundColor: '#007bff',
//                   color: '#fff',
//                   border: 'none',
//                   cursor: 'pointer',
//                   fontSize: '1rem',
//                   boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//                 }}
//                 onClick={() =>
//                   window.open('https://www.hageconcrete.com/schedule-an-estimate', '_blank')
//                 }
//               >
//                 Schedule an Estimate
//               </button>
//             </div>
//             <button className="dark-mode-toggle" onClick={toggleDarkMode}>
//               <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
//             </button>
//           </header>

//           {/* Map Section */}
//           <MapContainer center={userLocation} zoom={15} className="map">
//             <TileLayer
//               url={isDarkMode ? darkStyle : lightStyle}
//               attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
//             />
//             <MapBoundsUpdater setMapBounds={setMapBounds} />
//             <RecenterMap center={userLocation} />
//             {/* New Map Controls on the left side */}
//             <MapControls userLocation={userLocation} />
//             <Marker position={userLocation} icon={userIcon} zIndexOffset={1000}>
//               <Popup>
//                 <strong>Your Location</strong>
//               </Popup>
//             </Marker>
//             <Circle
//               center={userLocation}
//               radius={1609.34} // 1 mile in meters
//               color="blue"
//               fillColor="blue"
//               fillOpacity={0.2}
//             />
//             <MarkerClusterGroup
//               chunkedLoading
//               showCoverageOnHover={true}
//               maxClusterRadius={50}
//               spiderfyOnMaxZoom={false}
//               disableClusteringAtZoom={15}
//               removeOutsideVisibleBounds={true}
//             >
//               {markerElements}
//             </MarkerClusterGroup>
//           </MapContainer>

//           {/* Job Details Panel */}
//           <div className={`details-panel ${selectedJob ? '' : 'no-selection'}`}>
//             {selectedJob ? (
//               <div style={{ padding: '1rem' }}>
//                 <h2
//                   style={{
//                     fontSize: '1.5rem',
//                     marginBottom: '0.5rem',
//                     borderBottom: '2px solid #ccc',
//                     paddingBottom: '0.5rem',
//                   }}
//                 >
//                   {selectedJob.jobAddress}
//                 </h2>
//                 <div style={{ marginTop: '1rem' }}>
//                   {selectedJob.projects && selectedJob.projects.length > 0 ? (
//                     <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
//                       {selectedJob.projects.map((project, index) => (
//                         <li key={index} style={{ marginBottom: '0.5rem' }}>
//                           {project.projectType} - Installed in {project.installYear}
//                         </li>
//                       ))}
//                     </ul>
//                   ) : (
//                     <p>No projects available.</p>
//                   )}
//                 </div>
//                 <button
//                   className="close-button"
//                   onClick={() => setSelectedJob(null)}
//                   style={{
//                     marginTop: '1rem',
//                     padding: '0.5rem 1rem',
//                     backgroundColor: '#007bff',
//                     color: '#fff',
//                     border: 'none',
//                     borderRadius: '5px',
//                     cursor: 'pointer',
//                   }}
//                 >
//                   Close
//                 </button>
//               </div>
//             ) : (
//               <div>
//                 <p>Select a marker to view job details.</p>
//               </div>
//             )}
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default MapView;


import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './MapView.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import jobData from '../data/jobs.json';

import houseIcon from '../data/street-view-solid1.svg';
import locationPinIcon from '../data/markerCircle.svg';

// Import SVG icons for map controls
import minusSolid from '../data/minus-solid.svg';
import plusSolid from '../data/plus-solid.svg';
import crosshairsSolid from '../data/crosshairs-solid.svg';

// Geocode helper function: converts an address string into coordinates using Mapbox Geocoding API.
async function geocodeAddress(address) {
  const encodedAddress = encodeURIComponent(address);
  // Replace 'YOUR_MAPBOX_TOKEN' with your actual Mapbox access token.
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=pk.eyJ1Ijoiam9zaHloYWdlIiwiYSI6ImNtMmkxN3FhdzBmeDcyaXE4cXVuOGF2MHYifQ.wjchcDg7q_zT_kJ7qxJEiw`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Geocoding request failed');
  }
  const data = await response.json();
  if (!data.features || data.features.length === 0) {
    throw new Error('No matching location found');
  }
  const [lng, lat] = data.features[0].center;
  return { latitude: lat, longitude: lng };
}

// Create custom icons
const customIcon = L.icon({
  iconUrl: locationPinIcon,
  iconSize: [30, 30],
  iconAnchor: [11, 36],
  popupAnchor: [0, -36],
});

const userIcon = L.icon({
  iconUrl: houseIcon,
  iconSize: [40, 55],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

// Component to recenter the map when the center prop changes
const RecenterMap = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center) {
      map.setView(center, map.getZoom());
    }
  }, [center, map]);
  return null;
};

// Component to update map bounds with a slight delay (debounce) before re-rendering markers
const MapBoundsUpdater = ({ setMapBounds }) => {
  const map = useMap();
  useEffect(() => {
    let timeoutId;
    const updateBounds = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setMapBounds(map.getBounds());
      }, 200);
    };
    map.on('moveend', updateBounds);
    updateBounds();
    return () => {
      map.off('moveend', updateBounds);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [map, setMapBounds]);
  return null;
};

// New component for map controls (zoom in, zoom out, recenter)
const MapControls = ({ userLocation }) => {
  const map = useMap();
  return (
    <div
      style={{
        position: 'absolute',
        left: '10px',
        top: '200px', // Adjust as needed
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <button
        onClick={() => map.zoomIn()}
        style={{
          padding: '0.5rem',
          borderRadius: '4px',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          cursor: 'pointer',
        }}
      >
        <img src={plusSolid} alt="Zoom In" style={{ width: '24px', height: '24px' }} />
      </button>
      <button
        onClick={() => map.zoomOut()}
        style={{
          padding: '0.5rem',
          borderRadius: '4px',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          cursor: 'pointer',
        }}
      >
        <img src={minusSolid} alt="Zoom Out" style={{ width: '24px', height: '24px' }} />
      </button>
      <button
        onClick={() => map.setView(userLocation, map.getZoom())}
        style={{
          padding: '0.5rem',
          borderRadius: '4px',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          cursor: 'pointer',
        }}
      >
        <img src={crosshairsSolid} alt="Recenter" style={{ width: '24px', height: '24px' }} />
      </button>
    </div>
  );
};

const MapView = () => {
  // UI state
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showSplash, setShowSplash] = useState(true);

  // userAddress state for splash screen input
  const [userAddress, setUserAddress] = useState('');

  // Search bar state
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(searchTerm);

  // Map location state
  const [userLocation, setUserLocation] = useState([44.972109856286544, -93.26562490769442]); // Default location
  const [hasLocation, setHasLocation] = useState(false);
  const [mapBounds, setMapBounds] = useState(null);

  const defaultCoordinates = [44.972109856286544, -93.26562490769442]; // Minneapolis default

  // Larger icon for hover state
  const hoverIcon = useMemo(
    () =>
      L.icon({
        iconUrl: locationPinIcon,
        iconSize: [60, 60],
        iconAnchor: [13, 40],
        popupAnchor: [0, -40],
      }),
    []
  );

  // State to detect mobile devices (breakpoint at 768px)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Toggle dark mode
  const toggleDarkMode = useCallback(() => {
    setIsDarkMode((prevMode) => !prevMode);
  }, []);

  // Handle marker click
  const handleMarkerClick = useCallback((job) => {
    requestAnimationFrame(() => {
      setSelectedJob((prev) => (prev?.jobAddress === job.jobAddress ? prev : job));
    });
  }, []);

  // Handle search input changes
  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  // Debounce search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 300);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Handle "Continue" button on the splash screen
  const handleContinue = useCallback(async () => {
    try {
      if (userAddress.trim().length > 0) {
        const coords = await geocodeAddress(userAddress);
        setUserLocation([coords.latitude, coords.longitude]);
        setHasLocation(true);
      } else {
        setUserLocation([44.975269671312255, -93.26717766140855]);
        setHasLocation(true);
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
      setUserLocation([44.975269671312255, -93.26717766140855]);
      setHasLocation(true);
    }
    setShowSplash(false);
  }, [userAddress]);

  // Filter jobs based on debounced search term
  const filteredJobs = useMemo(() => {
    return jobData.filter((job) => {
      const term = debouncedSearch.toLowerCase();
      return (
        job.jobAddress.toLowerCase().includes(term) ||
        job.jobZone.toLowerCase().includes(term)
      );
    });
  }, [debouncedSearch]);

  // Filter jobs based on current map bounds
  const visibleJobs = useMemo(() => {
    if (!mapBounds) return filteredJobs;
    return filteredJobs.filter((job) => {
      if (
        !job.coordinates ||
        job.coordinates.latitude === undefined ||
        job.coordinates.longitude === undefined
      ) {
        return false;
      }
      return mapBounds.contains([job.coordinates.latitude, job.coordinates.longitude]);
    });
  }, [filteredJobs, mapBounds]);

  // Create marker elements (using a unique key if available)
  const markerElements = useMemo(() => {
    return visibleJobs.map((job) => (
      <Marker
        key={job.jobAddress}
        position={
          job.coordinates && job.coordinates.latitude !== undefined
            ? [job.coordinates.latitude, job.coordinates.longitude]
            : defaultCoordinates
        }
        icon={customIcon}
        eventHandlers={{
          click: () => handleMarkerClick(job),
          mouseover: (e) => e.target.setIcon(hoverIcon),
          mouseout: (e) => e.target.setIcon(customIcon),
        }}
      >
        <Popup>
          <strong>{job.jobAddress}</strong>
        </Popup>
      </Marker>
    ));
  }, [visibleJobs, hoverIcon, handleMarkerClick]);

  // Mapbox tile URLs
  const lightStyle =
    'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9zaHloYWdlIiwiYSI6ImNtMmkxN3FhdzBmeDcyaXE4cXVuOGF2MHYifQ.wjchcDg7q_zT_kJ7qxJEiw';
  const darkStyle =
    'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9zaHloYWdlIiwiYSI6ImNtMmkxN3FhdzBmeDcyaXE4cXVuOGF2MHYifQ.wjchcDg7q_zT_kJ7qxJEiw';

  return (
    <div className={`map-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      {showSplash ? (
        <div
          className="splash-screen"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            boxSizing: 'border-box',
            padding: '1rem',
          }}
        >
          <div
            className="splash-content"
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              maxWidth: '700px',
              width: '100%',
              boxSizing: 'border-box',
              padding: '2rem',
            }}
          >
            <div className="splash-banner">
              <a href="https://hageconcrete.com" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://imagedelivery.net/1Dx57cADC9zKorcji03Zsw/ee83fed1-48f0-4c20-7935-7941cfa5ff00/public"
                  alt="Hage Concrete Works"
                  style={{ maxWidth: '100%', height: 'auto', marginBottom: '1rem' }}
                />
              </a>
            </div>
            <h1 style={{ marginTop: 0 }}>Welcome to the Hage Concrete Works Project Map</h1>
            <p>
              This map helps you explore projects we've completed near you. Click on map markers to see
              detailed information about each project.
            </p>
            <p>
              Many projects can easily be seen from the street, sidewalk, or alley. We are proud of our
              work and hope you will take the time to visit some of these completed projects. As a
              courtesy to our valued customers, please respect their privacy and property.
            </p>
            <div style={{ margin: '1rem 0' }}>
              <label
                htmlFor="userAddress"
                style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold', color: '#333' }}
              >
                Enter Your Address to see projects near you:
              </label>
              <input
                id="userAddress"
                type="text"
                value={userAddress}
                onChange={(e) => setUserAddress(e.target.value)}
                placeholder="123 Main St, Minneapolis, MN"
                style={{
                  width: '75%',
                  padding: '0.75rem',
                  borderRadius: '10px',
                  border: '1px solid #ddd',
                  outline: 'none',
                  fontSize: '1rem',
                  transition: 'border 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                }}
                onFocus={(e) => {
                  e.target.style.border = '1px solid #007bff';
                  e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                }}
                onBlur={(e) => {
                  e.target.style.border = '1px solid #ddd';
                  e.target.style.boxShadow = 'none';
                }}
              />
            </div>
            <button className="continue-button" onClick={handleContinue}>
              Continue
            </button>
          </div>
        </div>
      ) : (
        <>
          {/* Header Section */}
          <header
            className="header"
            style={{ display: 'flex', alignItems: 'center', padding: '1rem' }}
          >
            <div className="banner">
              <img
                src={
                  isDarkMode
                    ? 'https://imagedelivery.net/1Dx57cADC9zKorcji03Zsw/a56c0fc0-61e1-499e-e781-2c8bf5b5b600/public'
                    : 'https://imagedelivery.net/1Dx57cADC9zKorcji03Zsw/ee83fed1-48f0-4c20-7935-7941cfa5ff00/public'
                }
                alt="Hage Concrete Works"
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexGrow: 1 }}>
              <div className="search-bar">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by address or job zone..."
                />
              </div>
              {/* Render schedule button only on desktop */}
              {!isMobile && (
                <button
                  style={{
                    padding: '0.75rem 1rem',
                    borderRadius: '5px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={() =>
                    window.open('https://www.hageconcrete.com/schedule-an-estimate', '_blank')
                  }
                >
                  Schedule an Estimate
                </button>
              )}
            </div>
            <button className="dark-mode-toggle" onClick={toggleDarkMode}>
              <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
            </button>
          </header>
          {/* On mobile, render the schedule button separately */}
          {isMobile && (
            <div
              style={{
                position: 'absolute',
                left: '10px',
                top: '110px', // Adjust based on header height
                zIndex: 1000,
              }}
            >
              <button
                style={{
                  padding: '0.75rem 1rem',
                  borderRadius: '10px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() =>
                  window.open('https://www.hageconcrete.com/schedule-an-estimate', '_blank')
                }
              >
                Schedule an Estimate
              </button>
            </div>
          )}

          {/* Map Section */}
          <MapContainer center={userLocation} zoom={15} className="map">
            <TileLayer
              url={isDarkMode ? darkStyle : lightStyle}
              attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
            />
            <MapBoundsUpdater setMapBounds={setMapBounds} />
            <RecenterMap center={userLocation} />
            {/* New Map Controls on the left side */}
            <MapControls userLocation={userLocation} />
            <Marker position={userLocation} icon={userIcon} zIndexOffset={1000}>
              <Popup>
                <strong>Your Location</strong>
              </Popup>
            </Marker>
            <Circle
              center={userLocation}
              radius={1609.34} // 1 mile in meters
              color="blue"
              fillColor="blue"
              fillOpacity={0.2}
            />
            <MarkerClusterGroup
              chunkedLoading
              showCoverageOnHover={true}
              maxClusterRadius={50}
              spiderfyOnMaxZoom={false}
              disableClusteringAtZoom={15}
              removeOutsideVisibleBounds={true}
            >
              {markerElements}
            </MarkerClusterGroup>
          </MapContainer>

          {/* Job Details Panel */}
          <div className={`details-panel ${selectedJob ? '' : 'no-selection'}`}>
            {selectedJob ? (
              <div style={{ padding: '1rem' }}>
                <h2
                  style={{
                    fontSize: '1.5rem',
                    marginBottom: '0.5rem',
                    borderBottom: '2px solid #ccc',
                    paddingBottom: '0.5rem',
                  }}
                >
                  {selectedJob.jobAddress}
                </h2>
                <div style={{ marginTop: '1rem' }}>
                  {selectedJob.projects && selectedJob.projects.length > 0 ? (
                    <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                      {selectedJob.projects.map((project, index) => (
                        <li key={index} style={{ marginBottom: '0.5rem' }}>
                          {project.projectType} - Installed in {project.installYear}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No projects available.</p>
                  )}
                </div>
                <button
                  className="close-button"
                  onClick={() => setSelectedJob(null)}
                  style={{
                    marginTop: '1rem',
                    padding: '0.5rem 1rem',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Close
                </button>
              </div>
            ) : (
              <div>
                <p>Select a marker to view job details.</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MapView;